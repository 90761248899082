<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" v-if="user">
              {{ user.email }}
            </p>
            <b-badge
              style="font-size: 10px; padding: 3px 5px"
              variant="danger"
              v-if="$acl.check('admin')"
            >
              Адміністратор
            </b-badge>
            <b-badge
              style="font-size: 10px; padding: 3px 5px"
              variant="primary"
              v-if="$acl.not.check('admin')"
            >
              Працівник
            </b-badge>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="avatarLetters"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center">
          <router-link :to="{ name: 'profile' }">
            <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
            <span>Мій профіль</span>
          </router-link>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Вийти</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    avatarLetters() {
      var letters = "AB";
      if (this.user) {
        letters = this.user.email[0] + this.user.email[1];
      }
      return letters;
    },
  },
  methods: {
    async logOut() {
      await this.$auth.logOut();
      this.$router.push("/login");
    },
  },
};
</script>
